
$font: "proxima-nova", sans-serif;
$body_width: 1200px;

/* colors */
$txt: #535353;

$primary: #437795; /* blue */
$secondary: #D0B669; /* gold */
$tertiary: #39A095; /* green */
$quaternary: #BEE5EF; /* light blue */

$tint: #DDDDDD;
$tint2: #BCBEC0; /* home content only */
$dark: #3B4150;

/* overlays */
$darken1: #4B483F;
$darken2: #0F0E0D;

/* common functions */
@mixin button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    max-width: 100%;
    color: #FFF;
    background-color: #000;
    box-sizing: border-box;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    &:hover {
        background-color: $dark;
    }
}

@mixin gradient_overlay($percent: 0.13, $opacity: 0.55) { /** home banner used as default **/
    position: relative;
    &::before {
        content:"";
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: rgba($darken1, $percent);
        background: -webkit-linear-gradient(top, rgba($darken1, $percent), rgba($darken2, 1.0));
        background: -moz-linear-gradient(top, rgba($darken1, $percent), rgba($darken2, 1.0));
        background: linear-gradient(to bottom, rgba($darken1, $percent), rgba($darken2, 1.0));;
        opacity: $opacity;
        mix-blend-mode: multiply;
    }
}

@mixin darken($opacity: 0.8) { /** page-banner used as default **/
    position: relative;
    &::before {
        content:"";
        position: absolute;
        top: 0px;
        left: 0px;
        display: block;
        width: 100%;
        height: 100%;
        background-color: $darken1;
        opacity: $opacity;
        mix-blend-mode: multiply;
    }
}