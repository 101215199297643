@import 'vars';

body {
    font-family: $font;
    font-size: 16px;
    color: #000;
    background-color: $tint; /* so we can see white circle icons */
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}

h1, h2, h3 {
    line-height: 1;
}

h3 {
    font-size: 1.125em;
}

.clear-float {
    clear:both;
}

/* link styles */
a {
/* automatic download style */
    &[href$='.pdf'] { /*  ends in pdf */
        display: inline-block;
        text-transform: uppercase;
        text-decoration: none;
        padding-right: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #FFF;
        margin-bottom: 10px;
        &::before {
            content:"";
            display: inline-block;
            vertical-align: middle;
            width: 24px;
            height: 24px;
            background-color: #FFF;
            border-radius: 50%;
            background-image: url('../assets/download.svg');
            background-size: 50%;
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 10px;
        }
    }
/** assigned in editor**/
    &.call-to-action, .call-to-action {
        display: block;
        font-weight: bold;
        text-decoration: none;
        font-size: 1.125rem;
        padding-bottom: 10px;
        margin-bottom: 10px;
        &::before {
            content:"";
            display: inline-block;
            vertical-align: middle;
            width: 32px;
            height: 32px;
            background-image: url('../assets/plus.svg');
            background-size: 16px;
            background-position: center;
            background-repeat: no-repeat;
            /*border: 1px solid #FFF;*/
            background-color: #ffffff;
            color:#000000;
            border-radius: 50%;
            margin-right: 20px;
        }
    }
}


/** assigned in editor **/
.button, a.button, .subpage-content a.button {
    @include button;
}
.primary {
    color: $primary; /* blue */
}
.secondary {
    color: $secondary; /* gold */
}
.tertiary {
    color: $tertiary; /* green */
}
.quaternary {
    color:$quaternary; /* light blue */
}
.highlight {
    font-size:30px;
}
img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}
img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}

ul.three-row-icons, 
ul.two-row-icons,
ul.row-icons {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    flex-direction: row;
    padding:0;
    li {
        margin: 5%;
        text-align: center;
        border-bottom:none;
        padding:0;
    }
}
ul.three-row-icons {
    justify-content: space-around;
    li {
        flex-basis: 20%;
    }
}
ul.two-row-icons {
    li {
        flex-basis: 48%;
        text-align: left;
        margin:1%;
        img {
            margin-right:1em;
        }
    }
}

ul.underline {
    list-style-type: none;
    padding:0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    li {
        border-bottom: 1px solid white;
        display: inline;
        padding: 25px 0;
    }
}
@media only screen and (max-width: 960px){
    ul.three-row-icons li {
        flex-basis: 40%;
    }
}
@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
    ul.three-row-icons {
        display: block;
    }
}